<template>
  <div
    v-if="validateSession"
    class="auth-wrapper auth-v1 px-2"
  >
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <!-- Brand logo-->
        <div class="i-brand-logo">
          <light-vertical-ostrail-logo />
        </div>
        <!-- /Brand logo-->

        <!-- Forgot password-->
        <div class="mt-1">
          <b-card-title class="mb-1">
            <!-- {{ $t('loginMessages.forgotPassword') }} 🔒 -->
            Hola <strong> {{ fullName }}</strong>, hemos detectado tu sesión anterior ¿Deseas continuar con esta sesión?
          </b-card-title>
          <b-card-text class="mb-2">
            <!-- {{ $t('loginMessages.enterEmail') }} -->
            Para garantizar la seguridad de tu información es necesario volver a ingresar tu contraseña.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group>
                <label for="password">{{ $t('loginMessages.password') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required|min:8|password"
                >
                  <b-form-input
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                    :placeholder="$t('loginMessages.password')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="dark"
                block
                pill
                :disabled="showSpinnerFP"
              >
                <b-spinner
                  v-if="showSpinnerFP"
                  small
                />
                <span class="ml-spinner">
                  <!-- {{ $t('loginMessages.sendResetLink') }} -->
                  Si, continuar con la sesión
                </span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link
              :to="{name:'auth-login'}"
              @click.stop="cancelLogin"
            >
              <i class="icon-0-icons-dark-step-back" />
              <!-- {{ $t('loginMessages.backToLogin') }} -->
              No, regresar al inicio
            </b-link>
          </p>
          <br>
        </div>
      <!-- /Forgot password-->
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable global-require */
import store from '@/store/index'
import { BCard, BLink, BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import authService from '@/services/authService'
import LightVerticalOstrailLogo from '@/views/partials/LightVerticalOstrailLogo.vue'

export default {
  components: {
    BCard,
    BLink,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    LightVerticalOstrailLogo,
  },

  props: {
    validateSession: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fullName: '',
      showSpinnerFP: false,
      userEmail: '',
      required, // validation
      password: '',
    }
  },

  mounted() {
    this.fullName = store.state.userAuthStore.fullName
  },

  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          console.log('hola')
          this.showSpinnerFP = true

          authService.setValidationPassword({ password: this.password }).then(({ data }) => {
            this.showSpinnerFP = false
            this.responseSuccess(data.message[0])
            this.$emit('update-modal-value', true)
            //setTimeout(() => { this.$router.replace('/') }, 1500)
          }).catch(error => {
            this.showSpinnerFP = false
            this.responseCatch(error)
          })
        }
      })
    },
    cancelLogin() {
      this.password = null
      this.$emit('update-modal-value', false)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
