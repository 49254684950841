<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <re-authenticate
      v-if="auxiliar.toString().length > 3"
      :validate-session="validateSession"
      @update-modal-value="updateModalValue"
    />

    <div v-if="!validateSession">
      <component :is="layout">
        <router-view />
      </component>

      <scroll-to-top v-if="enableScrollToTop" />

      <div v-if="showTimer">
        <timer-component
          :timer="timer"
          @close-modal="closeModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
// This will be populated in `beforeCreate` hook
/* eslint-disable object-curly-newline */
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useIdle, useTimestamp, useWindowSize, useCssVar } from '@vueuse/core'

import authService from '@/services/authService'

import Echo from 'laravel-echo'
import { isUserLoggedIn } from '@/auth/utils'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import TimerComponent from '@/TimerComponent.vue'
import ReAuthenticate from './views/pages/authentication/ReAuthenticate.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

window.Pusher = require('pusher-js')

export default {
  components: {
    TimerComponent,
    ScrollToTop,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ReAuthenticate,
  },

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return store.state.appConfig.layout.type
    },
    validateSession() {
      return (
        this.stringWebPage !== 'UwtnCMi4YyNDZjMi1iMjFiLTRlNzRODL8JEEYENY2538971765soEYThJD' &&
        this.generatedFinalString.includes(this.auxiliar)
      )
    },
  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },

  setup() {
    let now = { timestamp: ref(0) }
    // eslint-disable-next-line prefer-const
    let intervalX = ref(null)
    // eslint-disable-next-line prefer-const
    let intervalAuth = ref(null)
    let stringWebPage = ref('UwtnCMi4YyNDZjMi1iMjFiLTRlNzRODL8JEEYENY2538971765soEYThJD')

    if (isUserLoggedIn()) {
      now = useTimestamp()
    }

    intervalX.value = setInterval(() => {
      if (isUserLoggedIn()) {
        const data = { current_value: 'authorized' }
        authService.setNewRequest(data).then(({ resp }) => {
          const result = resp
        })
      } // clearInterval(intervalX.value)
    }, 120000) /* 1 minuto */

    const counter = ref(0)
    const auxTime = ref(0)
    const showTimer = ref(false)
    const applyTimer = ref(false)

    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    const { idle, lastActive } = useIdle(600000) // useIdle(10 * 60 * 1000), 10 minutos

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()

    const assignColors = () => {
      store.dispatch('setColorValueSidebar', '#33334d')
      store.dispatch('setColorValue', '#6abeff')
    }

    const closeModal = () => {
      showTimer.value = false
      counter.value = 0
    }

    const generateRandomString = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      let result = ''

      for (let i = 0; i < 8; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
      }

      return result
    }

    const generateRandomNumber = () => {
      const characters = '0123456789'
      let result = ''

      for (let i = 0; i < 3; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length))
      }

      return result
    }

    let auxiliar = isUserLoggedIn() ? ref(store.state.userAuthStore.id) : ref(generateRandomNumber())
    const generatedString = ref(generateRandomString())
    const generatedStringTwo = ref(generateRandomString())
    const generatedFinalString = ref(generatedString.value + auxiliar.value + generatedStringTwo.value)

    onMounted(() => {
      assignColors()

      if (isUserLoggedIn()) {
        const sessionString = sessionStorage.getItem('web-page')

        if (sessionString == null || sessionString.length != 17) {
          stringWebPage.value = generateRandomString() + 'YyNDZjMi1iMjFiLTRlNzRODL8JEEYENY' + auxiliar.value + generateRandomString()
        }
      }

      intervalAuth.value = setInterval(() => {
        if (isUserLoggedIn()) {
          window.Echo = new Echo({
            broadcaster: 'pusher',
            key: '923820dcc509a6f75849',
            authEndpoint: 'http://localhost:8000/broadcasting/auth',
            wsHost: window.location.hostname, // 'http://ostrail_backend.test'
            wsPort: 6001,
            forceTLS: false,
            encrypted: true,
            disableStats: true,
            enabledTransports: ['ws'],
            auth: {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            },
          })

          clearInterval(intervalAuth.value)
        }
      }, 10000) /* 10 segundos */
    })

    const timer = computed(() => ((auxTime.value - now.timestamp.value) / 1000).toFixed(0))

    watch([windowWidth, idle, timer, auxTime], val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val[0])

      if (isUserLoggedIn()) {
        if (val[1]) {
          counter.value += 1

          if (counter.value == 1) {
            auxTime.value = lastActive.value + 660000 // 11 minutos = (10 minutos de inactividad + 1 minuto que se respeta para mostrar el aviso de cierre de sesión)

            setTimeout(() => {
              applyTimer.value = true
              showTimer.value = true
            }, 500)
          }
        }

        if (val[2] <= 0 && applyTimer.value) {
          if (showTimer.value) {
            store.dispatch('deleteSessionInfo')
            sessionStorage.removeItem('web-page')
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('vuex')
            localStorage.clear()
            assignColors()
            window.location.reload()
          }
        }
      }
    })

    return {
      isUserLoggedIn,
      stringWebPage,
      generatedFinalString,
      auxiliar,
      timer,
      showTimer,
      skinClasses,
      enableScrollToTop,
      closeModal,
      updateModalValue(value) {
        if (!value) {
          store.dispatch('deleteSessionInfo')
          sessionStorage.removeItem('web-page')
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
          localStorage.removeItem('vuex')
          localStorage.clear()
          assignColors()
          window.location.reload()
        } else {
          stringWebPage.value = 'UwtnCMi4YyNDZjMi1iMjFiLTRlNzRODL8JEEYENY2538971765soEYThJD'
        }
      },
    }
  },
}
</script>
